const ExercisesActionTypes = {
  ADD_EXERCISE_TO_LIST: 'ADD_EXERCISE_TO_LIST',
  TOGGLE_BUTTON_PRESSED: 'TOGGLE_BUTTON_PRESSED',
  FETCH_EXERCISES_START: 'FETCH_EXERCISES_START',
  FETCH_EXERCISES_SUCCESS: 'FETCH_EXERCISES_SUCCESS',
  FETCH_EXERCISES_FAILURE: 'FETCH_EXERCISES_FAILURE',
  SHOW_NEXT_EXERCISES: 'SHOW_NEXT_EXERCISES',
  SHOW_PREV_EXERCISES: 'SHOW_PREV_EXERCISES',
};

export default ExercisesActionTypes;
